import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_EMPLOYEE = 'SET_EMPLOYEE'
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        employee: {},
        isLoading: false,
        employees: [],
        currentPage: 1,
    },
    mutations: {
        [SET_EMPLOYEE](state, employee) {
            state.employee = employee;
        },
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_EMPLOYEES](state, employees) {
            state.employees = employees;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getEmployees({ commit, state }) {
            commit(SET_LOADING, true);
            axios
                .get("api/employees?page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_EMPLOYEES, response.data)
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getEmployeesAll({ commit }, filters) {
            if (filters == undefined) filters = '';
            axios
                .get("api/employees?" + filters)
                .then((response) => {
                    commit(SET_EMPLOYEES, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createEmployee({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/employees", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Empleado creada correctamente",
                        type: 'success'
                    });
                    dispatch('getEmployees');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateEmployee({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .put("api/employees/" + payload.id, payload.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Empleado editado correctamente",
                        type: 'success'
                    });
                    dispatch('getEmployees');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        getEmployee({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .get("api/employees/" + id)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_EMPLOYEE, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        addFileEmployee({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/employee_files", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Documento agregado correctamente",
                        type: 'success'
                    });
                    dispatch('getEmployee', form.get('employee'));
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteFileEmployee({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .delete("api/employee_files/" + form.file)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Documento eliminado correctamente",
                        type: 'success'
                    });
                    dispatch('getEmployee', form.employee);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        nextPageEmployees({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getEmployees');
        }
    },
};
