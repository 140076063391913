import axios from "axios";
import { SET_ALERT } from "../../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_LOANS = 'SET_LOANS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        loans: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_LOANS](state, loans) {
            state.loans = loans;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getLoans({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("api/loans?"+ filters +"page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_LOANS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
       
        nextPageLoans({ commit, dispatch }, load) {
            commit(SET_CURRENT_PAGE, load.page);
            dispatch('getLoans', load.filters);
        }
    },
};
