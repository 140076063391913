import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_OFFICES = 'SET_OFFICES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        offices: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_OFFICES](state, offices) {
            state.offices = offices;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getOffices({ commit, state }) {
            commit(SET_LOADING, true);
            axios
                .get("api/offices?page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_OFFICES, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getOfficesAll({ commit }) {            
            axios
                .get("api/offices")
                .then((response) => {
                    commit(SET_OFFICES, response.data)
                })
                .catch((error) => {                    
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
      
        createOffice({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/offices", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Sede creada correctamente",
                        type: 'success'
                    });
                    dispatch('getOffices');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateOffice({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .put("api/offices/" + payload.id, payload.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Sede editada correctamente",
                        type: 'success'
                    });
                    dispatch('getOffices');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        nextPageOffices({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getOffices');
        }
    },
};
