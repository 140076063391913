export default {
    state: {
        statusLoan: [
            {
                text: "ACTIVO",
                value: "ACTIVE"
            },
            {
                text: "ENTREGADO",
                value: "DELIVERED"
            },
            {
                text: "DEVUELTO",
                value: "RETURNED"
            },
            {
                text: "APLAZADO",
                value: "POSTPONED"
            },
            {
                text: "PAGADO",
                value: "PAID"
            },
        ],
        statusArrangements: [
            {
                text: "PENDIENTE",
                value: "PENDING"
            },
            {
                text: "HECHO",
                value: "DONE"
            },
        ],
        payments_types: [
            {
                text: "INICIAL",
                value: "INITIAL"
            },
            {
                text: "CREDITO",
                value: "CREDIT"
            },
            {
                text: "FOTO ESTUDIO",
                value: "PHOTO_STUDIO"
            },
        ],
        payments_methods: [
            {
                text: "EFECTIVO",
                value: "CASH"
            },
            {
                text: "TRANSFERENCIA",
                value: "TRANSFER"
            },
            {
                text: "DATAFONO",
                value: "DATAPHONE"
            },
            {
                text: "TARJETA REGALO",
                value: "GIFT_CARD"
            },
        ],
        payments_origines: [
            {
                text: "VENTAS",
                value: "sales"
            },
            {
                text: "ALQUILERES",
                value: "loans"
            },
        ],
        employeePosition: [
            {
                text: "ADMINISTRATIVO",
                value: "ADMINISTRATIVE"
            },
            {
                text: "ASESOR",
                value: "ADVISER"
            },
        ],
        statusWash: [
            {
                text: "SI",
                value: "SI"
            },
            {
                text: "NO",
                value: "NO"
            },
        ]
    },
    getters: {
        getStatusColor: () => (status) => {
            switch (status) {
                case "DONE":
                default:
                    return "green";
                case "INACTIVE":
                case "SOLD":
                    return "red";
                case "PENDING":
                    return "blue";
            }
        },
        getStatusLoanColor: () => (status) => {
            switch (status) {
                case "ACTIVE":
                    return "yellow darken-1";
                case "RETURNED":
                default:
                    return "green";
                case "POSTPONED":
                    return "red";
                case "DELIVERED":
                    return "blue";
            }
        },
        getStatusLan: () => (status) => {
            switch (status) {
                case "ACTIVE":
                default:
                    return "ACTIVO";
                case "INACTIVE":
                    return "INACTIVO";
                case "SOLD":
                    return "VENDIDO";
                case "PENDING":
                    return "PENDIENTE";
                case "DONE":
                    return "HECHO";
                case "DELIVERED":
                    return "ENTREGADO";
                case "RETURNED":
                    return "DEVUELTO";
                case "POSTPONED":
                    return "APLAZADO";
                case "PAID":
                    return "PAGADO";
            }
        },
        getPaymentMethodsLan: () => (method) => {
            switch (method) {
                case "CASH":
                default:
                    return "EFECTIVO";
                case "TRANSFER":
                    return "TRANSFERENCIA";
                case "DATAPHONE":
                    return "DATAFONO";
                case "GIFT_CARD":
                    return "TARJETA REGALO";
            }
        },
        getPaymentTypeLan: () => (method) => {
            switch (method) {
                case "INITIAL":
                default:
                    return "INICIAL";
                case "CREDIT":
                    return "ABONO";
                case "PHOTO_STUDIO":
                    return "FOTO ESTUDIO";
            }
        },
        getEmployeePositionLan: () => (method) => {
            switch (method) {
                case "ADMINISTRATIVE":
                default:
                    return "ADMINISTRATIVO";
                case "ADVISER":
                    return "ASESOR";
            }
        }
    },
};
