import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOAN_SUMMARY = 'SET_LOAN_SUMMARY';
export const SET_SALE_SUMMARY = 'SET_SALE_SUMMARY';
export const SET_ARRANGEMENT_SUMMARY = 'SET_ARRANGEMENT_SUMMARY';
export const SET_PAYMENT_SUMMARY = 'SET_PAYMENT_SUMMARY';

export default {
    state: {
        loan_summary: {},
        sale_summary: {},
        arrangement_summary: {},
        payment_summary: {},
    },
    mutations: {
        [SET_LOAN_SUMMARY](state, loan_summary) {
            state.loan_summary = loan_summary;
        },
        [SET_SALE_SUMMARY](state, sale_summary) {
            state.sale_summary = sale_summary;
        },
        [SET_ARRANGEMENT_SUMMARY](state, arrangement_summary) {
            state.arrangement_summary = arrangement_summary;
        },
        [SET_PAYMENT_SUMMARY](state, payment_summary) {
            state.payment_summary = payment_summary;
        },
    },
    actions: {
        getLoanSummary({ commit }, filters) {
            axios
                .get("api/loan_summaries?" + filters)
                .then((response) => {
                    commit(SET_LOAN_SUMMARY, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getSaleSummary({ commit }, filters) {
            axios
                .get("api/sale_summaries?" + filters)
                .then((response) => {
                    commit(SET_SALE_SUMMARY, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getArrangementSummary({ commit }, filters) {
            axios
                .get("api/arrangement_summaries?" + filters)
                .then((response) => {
                    commit(SET_ARRANGEMENT_SUMMARY, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getPaymentSummary({ commit }, filters) {
            axios
                .get("api/payment_summaries?" + filters)
                .then((response) => {
                    commit(SET_PAYMENT_SUMMARY, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
};
