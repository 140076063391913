import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_CLIENT = 'SET_CLIENT';

export default {
    state: {
        isLoading: false,
        client: {},        
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_CLIENT](state, client) {
            state.client = client;
        },
    },
    actions: {
        getClient({ commit }, colombian_id) {
            commit(SET_LOADING, true);
            axios
                .get("api/clients?colombian_id=" + colombian_id)
                .then((response) => {
                    commit(SET_LOADING, false);
                    if(response.data.length){
                        commit(SET_CLIENT, response.data[0]);
                    }                    
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        createClient({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/clients", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cliente creado correctamente",
                        type: 'success'
                    });   
                    dispatch("getClient", form.colombian_id);                 
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateClient({ commit }, payloads) {
            commit(SET_LOADING, true);
            axios
                .patch("api/clients/" + payloads.id, payloads.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Cliente editado correctamente",
                        type: 'success'
                    });                    
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },
    },
};
