import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login.vue')
  },
  {
    path: '/',
    component: () => import('../views/layout/Layout.vue'),
    meta: { requiresAuth: true },
    children: [
      //Dashboard
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Dashboard.vue'),
      },
      //Users
      {
        path: '/users',
        name: 'Users',
        component: () => import('../views/user/Users.vue'),
      },
      //Categories
      {
        path: '/categories',
        name: 'Categories',
        component: () => import('../views/category/Categories.vue'),
      },
      //Dress
      {
        path: '/dresses',
        name: 'Dresses',
        component: () => import('../views/dress/Dresses.vue'),
      },
      //Loan
      {
        path: '/loans',
        name: 'Loans',
        component: () => import('../views/loan/Loans.vue'),
      },
      {
        path: '/loans/create',
        name: 'CreateLoan',
        component: () => import('../views/loan/Create.vue'),
      },
      {
        path: '/loans/show/:id',
        name: 'ShowLoan',
        props: true,
        component: () => import('../views/loan/Show.vue'),
      },
      {
        path: '/loans/edit/:id',
        name: 'EditLoan',
        props: true,
        component: () => import('../views/loan/Create.vue'),
      },
      //Arrangement
      {
        path: '/arrangements',
        name: 'Arrangements',
        component: () => import('../views/arrangement/Arrangements.vue'),
      },
      //Payments
      {
        path: '/payments',
        name: 'Payments',
        component: () => import('../views/payment/Payments.vue'),
      },
      //Sale
      {
        path: '/sales',
        name: 'Sales',
        component: () => import('../views/sale/Sales.vue'),
      },
      {
        path: '/sales/create',
        name: 'CreateSale',
        component: () => import('../views/sale/Create.vue'),
      },
      {
        path: '/sales/show/:id',
        name: 'ShowSale',
        props: true,
        component: () => import('../views/sale/Show.vue'),
      },
      {
        path: '/sales/edit/:id',
        name: 'EditSale',
        props: true,
        component: () => import('../views/sale/Create.vue'),
      },
      //Office
      {
        path: '/offices',
        name: 'Offices',
        component: () => import('../views/offices/Offices.vue'),
      },
      //Employee
      {
        path: '/employees',
        name: 'Employees',
        component: () => import('../views/employee/Employees.vue'),
      },
      {
        path: '/employees/show/:id',
        name: 'ShowEmployee',
        props: true,
        component: () => import('../views/employee/Show.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!document.cookie.split(';').find(x => x.match(/XSRF-TOKEN/g))) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
