import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_ARRANGEMENTS = 'SET_ARRANGEMENTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        arrangements: [],
        currentPage: 1,        
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_ARRANGEMENTS](state, arrangements) {
            state.arrangements = arrangements;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getArrangements({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("api/arrangements?"+ filters +"page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ARRANGEMENTS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },        

        updateArrangement({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .patch("api/arrangements/" + payload.id, payload.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Arreglo editado correctamente",
                        type: 'success'
                    });
                    dispatch('getArrangements');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteArrangement({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/arrangements/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Arreglo eliminado correctamente",
                        type: 'success'
                    });                    
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        nextPageArrangements({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getArrangements');
        }
    },
};
