import Vue from 'vue'
import Vuex from 'vuex'
import alert from './alert'
import login from './login'
import user from './user'
import categories from './categories'
import dresses from './dresses'
import status from './status'
import loan from './loan'
import client from './client'
import arrangements from './arrangements'
import payments from './payments'
import sale from './sale'
import summaries from './summaries'
import dashboard from './dashboard'
import note from './note'
import offices from './offices'
import employee from './employee'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    login,
    user,
    categories,
    dresses,
    status,
    loan,
    client,
    arrangements,
    payments,
    sale,
    summaries,
    dashboard,    
    note,
    offices,
    employee,
  }
})
