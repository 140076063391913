<template>
  <div id="app">    
    <v-app>
      <router-view/>

      <message-alert />
    </v-app>    
  </div>
</template>

<script>
import MessageAlert from "@/components/shared/Alert.vue";
export default {
  name: "App",

  data: () => ({
    //
  }),

  components: {
    MessageAlert,
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
