import axios from "axios";
import router from "../../../router";
import { SET_ALERT } from "../../alert";

export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
    },
    actions: {
        createSale({ commit }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/sales", form)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Venta creada correctamente",
                        type: 'success'
                    });                    
                    window.open(response.data.contract_url, "w1", "status=1,toolbar=1,menubar=1");
                    window.open(response.data.invoice_url, "w2", "status=1,toolbar=1,menubar=1");
                    router.push("/sales");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },
    },
};
