import axios from "axios";
import router from "../../router";
import { SET_ALERT } from "../alert"
import create from "./create";
import loans from "./loans";

export const SET_LOADING = 'SET_LOADING';
export const SET_LOAN = 'SET_LOAN';

export default {
    state: {
        isLoading: false,
        loan: {},
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_LOAN](state, loan) {
            state.loan = loan;
        },
    },
    actions: {
        getLoan({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .get("api/loans/" + id)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_LOAN, response.data)
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getLoanFilters({ commit }, search) {
            commit(SET_LOADING, true);
            axios
                .get("api/loans?" + search)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_LOAN, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        updateLoan({ commit, dispatch }, payloads) {
            commit(SET_LOADING, true);
            axios
                .patch("api/loans/" + payloads.id, payloads.form)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Alquiler editado correctamente",
                        type: 'success'
                    });                    
                    if (router.currentRoute.name != 'Loans') {
                        window.open(response.data.contract_url, "w1", "status=1,toolbar=1,menubar=1");
                        window.open(response.data.invoice_url, "w2", "status=1,toolbar=1,menubar=1");
                        router.push("/loans");
                    }else{
                        dispatch("getLoans");
                    }                    
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        addPhotoStudio({ commit, dispatch }, payloads) {
            commit(SET_LOADING, true);
            axios
                .patch("api/photo_studios/" + payloads.id, payloads.form)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Foto Estudio agregado correctamente",
                        type: 'success'
                    });
                    window.open(response.data.loan_payment.payment.invoice_url, "_blank");
                    dispatch("getLoan", router.currentRoute.params.id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteLoan({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/loans/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Alquiler eliminado correctamente",
                        type: 'success'
                    });
                    dispatch("getLoans");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateLoanDress({ commit }, payloads) {
            if (typeof payloads.form.rental_value === "string")
                payloads.form.rental_value = payloads.form.rental_value.split(".").join("");

            if (typeof payloads.form.wash_value === "string")
                payloads.form.wash_value = payloads.form.wash_value.split(".").join("");

            payloads.form.arrangements.forEach((item) => {
                if (typeof item.value === "string")
                    item.value = item.value.split(".").join("");
            });

            commit(SET_LOADING, true);
            axios
                .patch("api/loan_dresses/" + payloads.id, payloads.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido editado correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteLoanDress({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/loan_dresses/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido eliminado de este alquiler correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateLoanPayment({ commit }, payloads) {
            if (typeof payloads.form.value === "string")
                payloads.form.value = payloads.form.value.split(".").join("");

            commit(SET_LOADING, true);
            axios
                .patch("api/loan_payments/" + payloads.id, payloads.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Pago editado correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteLoanPayment({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/loan_payments/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Pago eliminado de este alquiler correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        create,
        loans
    },
};
