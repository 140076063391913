import axios from "axios";
import { SET_ALERT } from "../alert"
import router from "../../router"
import users from "./users"

export const SET_USER = 'SET_USER';

export default {
    state: {
        user: null,
    },
    mutations: {
        [SET_USER](state, user) {
            state.user = user;
        }
    },
    actions: {
        getUser({ commit }) {
            axios
                .get("api/me")
                .then((response) => {
                    commit(SET_USER, response.data)
                })
                .catch(() => {
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                    commit(SET_ALERT, {
                        message: "Salio de la aplicación correctamente",
                        type: 'success'
                    });
                    router.push({ path: "/login" });                   
                });
        },
    },
    modules: {
        users
    }
};
