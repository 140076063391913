import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_DRESSES = 'SET_DRESSES';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        dresses: [],
        currentPage: 1,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_DRESSES](state, dresses) {
            state.dresses = dresses;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getDresses({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("api/dresses?"+ filters +"page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_DRESSES, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getDressesByCategory({ commit }, payload) {
            var params;
            if (typeof payload === 'object') {
                params = 'category_id=' + payload.category_id +
                    '&event_date=' + payload.event_date +
                    '&return_date=' + payload.return_date;
            } else {
                params = 'category_id=' + payload + '&process_type=sale';
            }

            axios
                .get("api/dresses?status=ACTIVE&" + params)
                .then((response) => {
                    commit(SET_DRESSES, response.data)
                })
                .catch((error) => {
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createDress({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/dresses", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido creado correctamente",
                        type: 'success'
                    });
                    dispatch('getDresses');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateDress({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .post("api/dresses/" + payload.id, payload.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido editado correctamente",
                        type: 'success'
                    });
                    dispatch('getDresses');
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },

        getDress({ commit }, id) {
            commit(SET_LOADING, true);
            return axios
                .get("api/dresses/" + id)
                .then((response) => {
                    commit(SET_LOADING, false);
                    return response.data;
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        nextPageDresses({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getDresses');
        }
    },
};
