export const SET_ALERT = 'SET_ALERT';

export default {
    state: {
        message: '',
        type: '',        
    },
    mutations: {
        [SET_ALERT](state, alert) {
            state.message = alert.message
            state.type = alert.type            
        }
    },
};
