import axios from "axios";
import router from "../../router"
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        }
    },
    actions: {
        async login({ commit, dispatch }, form) {
            commit(SET_LOADING, true);

            await axios.get("sanctum/csrf-cookie");
            await axios
                .post("api/login", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    dispatch("getUser");
                    router.push({ path: "/" });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        logout({ commit }) {            
            axios
                .post("api/logout")
                .then(() => {
                    document.cookie.split(";").forEach(function (c) {
                        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                    });
                    commit(SET_ALERT, {
                        message: "Salio de la aplicación correctamente",
                        type: 'success'
                    });
                    router.push({ path: "/login" });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
};
