import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export default {
    state: {
        isLoading: false,
        payments: [],
        currentPage: 1,
        paymentMethods: [
            { value: "CASH", text: "Efectivo" },
            { value: "TRANSFER", text: "Transferencia" },
            { value: "DATAPHONE", text: "Datáfono" },
            { value: "GIFT_CARD", text: "Tarjeta regalo" },
        ]
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_PAYMENTS](state, payments) {
            state.payments = payments;
        },
        [SET_CURRENT_PAGE](state, page) {
            state.currentPage = page;
        },
    },
    actions: {
        getPayments({ commit, state }, filters) {
            if(filters == undefined) filters = '';
            commit(SET_LOADING, true);
            axios
                .get("api/payments?"+ filters +"page=" + state.currentPage)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_PAYMENTS, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        createPayment({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/payments", form)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Pago creado correctamente",
                        type: 'success'
                    });
                    window.open(response.data.invoice_url, "_blank");
                    dispatch("getPayments");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        nextPagePayments({ commit, dispatch }, page) {
            commit(SET_CURRENT_PAGE, page);
            dispatch('getPayments');
        }
    },
};
