import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';
export const SET_DASHBOARD = 'SET_DASHBOARD';

export default {
    state: {
        isLoading: false,
        dashboard: {},
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_DASHBOARD](state, dashboard) {
            state.dashboard = dashboard;
        }
    },
    actions: {
        async getDashboard({ commit }) {
            commit(SET_LOADING, true);
            await axios
                .get("api/dashboards")
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_DASHBOARD, response.data);
                })
                .catch((error) => {
                    console.error(error);
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },
    },
};
