import axios from "axios";
import { SET_ALERT } from "../alert"

export const SET_LOADING = 'SET_LOADING';

export default {
    state: {
        isLoading: false,
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
    },
    actions: {
        createNote({ commit, dispatch }, form) {
            commit(SET_LOADING, true);
            axios
                .post("api/notes", form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Nota creada correctamente",
                        type: 'success'
                    });
                    dispatch('getLoan', form.loan_id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteNote({ commit, dispatch }, payload) {
            commit(SET_LOADING, true);
            axios
                .delete("api/notes/" + payload.note)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Nota eliminada correctamente",
                        type: 'success'
                    });
                    dispatch('getLoan', payload.loan_id);
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        })
                    }
                });
        },
    },
};
