import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import axios from 'axios'

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://api.vestidosdetemporada.com/'
axios.defaults.withCredentials = true
//http://localhost/aim/public/
//https://api.vestidosdetemporada.com/

if(document.cookie.split(';').find(x => x.match(/XSRF-TOKEN/g))){
  store.dispatch("getUser");
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
