import axios from "axios";
import router from "../../router";
import { SET_ALERT } from "../alert"
import sales from "./sales";
import create from "./create";

export const SET_LOADING = 'SET_LOADING';
export const SET_SALE = 'SET_SALE';

export default {
    state: {
        isLoading: false,
        sale: {},
    },
    mutations: {
        [SET_LOADING](state, loading) {
            state.isLoading = loading;
        },
        [SET_SALE](state, sale) {
            state.sale = sale;
        },
    },
    actions: {
        getSale({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .get("api/sales/" + id)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_SALE, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        getSaleFilters({ commit }, search) {
            commit(SET_LOADING, true);
            axios
                .get("api/sales?" + search)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_SALE, response.data)
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        commit(SET_ALERT, {
                            message: error.response.data.message,
                            type: 'error'
                        });
                    }
                });
        },

        updateSale({ commit }, payloads) {
            commit(SET_LOADING, true);
            axios
                .patch("api/sales/" + payloads.id, payloads.form)
                .then((response) => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Venta editada correctamente",
                        type: 'success'
                    });
                    window.open(response.data.contract_url, "w1", "status=1,toolbar=1,menubar=1");
                    window.open(response.data.invoice_url, "w2", "status=1,toolbar=1,menubar=1");
                    router.push("/sales");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteSale({ commit, dispatch }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/sales/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Venta eliminada correctamente",
                        type: 'success'
                    });
                    dispatch("getSales");
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateSaleDress({ commit }, payloads) {            
            commit(SET_LOADING, true);
            axios
                .patch("api/sale_dresses/" + payloads.id, payloads.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido editado correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteSaleDress({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/sale_dresses/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Vestido eliminado de esta venta correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        updateSalePayment({ commit }, payloads) {
            if (typeof payloads.form.value === "string")
                payloads.form.value = payloads.form.value.split(".").join("");

            commit(SET_LOADING, true);
            axios
                .patch("api/sale_payments/" + payloads.id, payloads.form)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Pago editado correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },

        deleteSalePayment({ commit }, id) {
            commit(SET_LOADING, true);
            axios
                .delete("api/sale_payments/" + id)
                .then(() => {
                    commit(SET_LOADING, false);
                    commit(SET_ALERT, {
                        message: "Pago eliminado de esta venta correctamente",
                        type: 'success'
                    });
                })
                .catch((error) => {
                    commit(SET_LOADING, false);
                    if (error.response.data) {
                        var message;
                        error.response.data.errors ?
                            message = error.response.data.errors[Object.keys(error.response.data.errors)[0]][0] :
                            message = error.response.data.message;

                        commit(SET_ALERT, {
                            message: message,
                            type: 'error'
                        })
                    }
                });
        },
    },
    modules: {
        sales,
        create
    },
};
